import axios from "axios";

const API_BASE_URL = "https://faastnfreash.com/api"; // Change to your PHP server URL

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
