import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

const LoginModal = ({ show, onClose, onLoginSuccess }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Load stored email and password if rememberMe is true
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");

    if (storedEmail && storedPassword) {
      setFormData({ email: storedEmail, password: storedPassword, rememberMe: true });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, rememberMe } = formData;

    // Frontend validation
    if (!email || !password) {
      setMessage("Please fill in all fields.");
      return;
    }

    setMessage("");
    setIsSubmitting(true);

    try {
      const response = await axios.post("https://faastnfreash.com/api/login.php", formData);

      if (response.data.success) {
        onLoginSuccess(response.data.user_id); // Pass user ID to the parent component
        setFormData({ email: "", password: "", rememberMe: false }); // Reset form
        onClose(); // Close the modal

        // If 'Remember Me' is checked, store credentials in localStorage
        if (rememberMe) {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password); // Note: Storing password is not secure!
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("password");
        }
      } else {
        setMessage(response.data.error || "Login failed. Please check your credentials.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="loginEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter email"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="loginPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter password"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Remember me"
              name="rememberMe"
              checked={formData.rememberMe}
              onChange={handleChange}
            />
          </Form.Group>
          {message && <Form.Text className="text-danger mb-3">{message}</Form.Text>}
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Logging in..." : "Login"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
