import React from "react";
import { Modal, Button, ListGroup, InputGroup } from "react-bootstrap";

const CartModal = ({ cart, onRemoveFromCart, show, onClose, onUpdateQuantity }) => {
  const totalPrice = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Shopping Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {cart.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <ListGroup>
            {cart.map((item) => (
              <ListGroup.Item key={item.id}>
                <div className="d-flex justify-content-between align-items-center">
                  <span>{item.name}</span>
                  <div className="d-flex align-items-center">
                    <Button
                      variant="outline-secondary"
                      onClick={() => onUpdateQuantity(item.id, 'decrease')}
                      disabled={item.quantity <= 1}  // Disable minus if quantity is 1
                    >
                      -
                    </Button>
                    <InputGroup.Text className="mx-2">{item.quantity}</InputGroup.Text>
                    <Button variant="outline-secondary" onClick={() => onUpdateQuantity(item.id, 'increase')}>
                      +
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      className="ms-2"
                      onClick={() => onRemoveFromCart(item.id)}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Price: ${item.price * item.quantity}</span>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <h4>Total: ${totalPrice.toFixed(2)}</h4>
        <Button variant="secondary" onClick={onClose}>Close</Button>
        <Button variant="primary">Checkout</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CartModal;
