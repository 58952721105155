import React, { useState, useEffect } from "react";
import { Card, Button, Container, Row, Col, Form } from "react-bootstrap";
import api from "../api";  // Assuming you have an API file

const MedicineList = ({ isLoggedIn, onAddToCart }) => {
  const [medicines, setMedicines] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    // Fetch medicines from the backend
    const fetchMedicines = async () => {
      try {
        const response = await api.get("/medicine_api.php"); // Fetch medicines
        setMedicines(response.data.medicines || []);
      } catch (error) {
        console.error("Error fetching medicines", error);
      }
    };

    fetchMedicines();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);  // Update search term
  };

  // Filter medicines based on search input
  const filteredMedicines = medicines.filter((med) =>
    med.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Container>
      <h2 className="my-4">Available Medicines</h2>
      <Form.Control
        type="text"
        placeholder="Search for medicines..."
        value={search}
        onChange={handleSearch}
        className="mb-4"
      />
      <Row>
        {filteredMedicines.map((medicine) => (
          <Col key={medicine.id} md={4} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{medicine.name}</Card.Title>
                <Card.Text>{medicine.description}</Card.Text>
                <Card.Text>Price: ${medicine.price}</Card.Text>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (!isLoggedIn) {
                      alert("Please log in to add medicines to your cart.");
                    } else {
                      onAddToCart(medicine); // Call onAddToCart when clicked
                    }
                  }}
                >
                  Add to Cart
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default MedicineList;
