import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import MedicineList from "./components/MedicineList";
import LoginModal from "./components/LoginModal";
import SignupForm from "./components/SignupForm";
import CartModal from "./components/CartModal";  // New Modal for displaying cart

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [loginEmail, setLoginEmail] = useState(""); // Store email for pre-fill after signup
  const [cart, setCart] = useState([]); // State to hold cart items
  const [showCart, setShowCart] = useState(false); // State to control the visibility of the cart modal

  // Automatically show login modal on app load if user is already in localStorage
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");

    if (storedEmail && storedPassword) {
      setLoginEmail(storedEmail);
      setShowLogin(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setShowLogin(false); // Close login modal
  };

  const handleSignupSuccess = (email) => {
    setShowSignup(false); // Close signup modal
    setLoginEmail(email); // Pre-fill email in login modal
    setShowLogin(true); // Automatically open login modal
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setLoginEmail("");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
  };

  // Handle adding product to the cart
  const handleAddToCart = (medicine) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item.id === medicine.id);
      if (existingItem) {
        return prevCart.map((item) =>
          item.id === medicine.id ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        return [...prevCart, { ...medicine, quantity: 1 }];
      }
    });
  };

  const handleRemoveFromCart = (medicineId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== medicineId));
  };

  const handleUpdateQuantity = (medicineId, action) => {
    setCart((prevCart) => {
      return prevCart.map((item) => {
        if (item.id === medicineId) {
          if (action === "increase") {
            return { ...item, quantity: item.quantity + 1 };
          } else if (action === "decrease" && item.quantity > 1) {
            return { ...item, quantity: item.quantity - 1 };
          }
        }
        return item;
      });
    });
  };

  const cartCount = cart.reduce((count, item) => count + item.quantity, 0); // Total item count in cart

  // Toggle cart modal visibility
  const handleCartClick = () => {
    setShowCart(!showCart);
  };

  return (
    <div>
      <Header
        isLoggedIn={isLoggedIn}
        onLoginClick={() => setShowLogin(true)}
        onSignupClick={() => setShowSignup(true)}
        onLogout={handleLogout}
        cartCount={cartCount}  // Pass cartCount to Header
        onCartClick={handleCartClick} // Pass cart click handler to Header
      />
      <MedicineList isLoggedIn={isLoggedIn} onAddToCart={handleAddToCart} />
      <LoginModal
        show={showLogin}
        onClose={() => setShowLogin(false)}
        onLoginSuccess={handleLoginSuccess}
        prefillEmail={loginEmail}
      />
      <SignupForm
        show={showSignup}
        onClose={() => setShowSignup(false)}
        onSignupSuccess={handleSignupSuccess}
      />
      {/* Cart Modal */}
      <CartModal
        cart={cart}
        onRemoveFromCart={handleRemoveFromCart}
        onUpdateQuantity={handleUpdateQuantity}  // Pass the quantity handler
        show={showCart}  // Only show if showCart is true
        onClose={() => setShowCart(false)}  // Close modal when user clicks close
      />
    </div>
  );
};

export default App;
