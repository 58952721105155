import React from "react";
import { Navbar, Nav, Button, Badge } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa";  // Import cart icon

const Header = ({ isLoggedIn, onLoginClick, onSignupClick, onLogout, cartCount, onCartClick }) => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href="#">Medicine Shop</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          {!isLoggedIn ? (
            <>
              <Button variant="outline-light" className="me-2" onClick={onLoginClick}>
                Login
              </Button>
              <Button variant="light" onClick={onSignupClick}>
                Sign Up
              </Button>
            </>
          ) : (
            <>
              <Button variant="outline-light" onClick={onLogout}>
                Logout
              </Button>
              {/* Cart icon with dynamic count */}
              <Nav.Link href="#cart" className="ms-3" onClick={onCartClick}>
                <FaShoppingCart />
                {cartCount > 0 && (
                  <Badge pill bg="danger" className="ms-2">
                    {cartCount} {/* Display cart item count */}
                  </Badge>
                )}
              </Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
